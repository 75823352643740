import React from 'react';
import { Grid, Typography, Button, Link, Chip } from '@material-ui/core'
import { useStyles } from './styles'
import Utils from '../../utils/utils';
import moment from 'moment';
import { Launch } from '@material-ui/icons';

const CardNews = ({ data, countWordsExtract }) => {
    const classes = useStyles();

    return (
        <Grid container className={classes.sectCardNews}>
            <Grid container item justify="space-between" alignItems="center">
                <Grid item className={classes.newsTagContainer}>
                    {data.tags && data.tags.split(',').map(t => <Chip className={classes.newsTag} label={t} />)}
                </Grid>
                <Grid item className={classes.dateContainer}>
                    {moment(data.createdAt).format('DD MMM YYYY')}
                </Grid>
            </Grid>

            <Grid item xs={12}>
                <Typography className={classes.title}>
                    {`${data.title.substring(0, countWordsExtract)}...`}
                </Typography>
                <Typography className={classes.extract}>
                    {Utils.getBriefText(data.extractFull)}
                </Typography>
            </Grid>
            <div className={classes.linkContainer}>
                <a href={Utils.getUrl(data.slug)}>
                    <Launch />
                </a>
            </div>
        </Grid>
    );
}

export default CardNews;