import { Grid, Hidden } from '@material-ui/core'
import { ArrowBackIos, ArrowForwardIos } from '@material-ui/icons'
import React, { Fragment, useCallback, useEffect } from 'react'
import { connect } from 'react-redux'
import Carousel from '@brainhubeu/react-carousel'
import '@brainhubeu/react-carousel/lib/style.css'
import { getBriefsNews } from '../../actions/newsActions'
import CardNews from '../cardNews'
import CardNews2 from '../cardNews2'
import CardNews3 from '../cardNews3'
import CardNews4 from '../cardNews4'
import CardNotNews from '../cardNotNews'

import { useStyles } from './styles'

const Latest = ({ news, getLatestNews, token, limit, url, query }) => {
  const classes = useStyles()

  const executeInitnews = async () => {
    await getLatestNews(url, token, query)
  }

  const conditionalRenderNews = (data) => {
    switch (data.newStyle) {
      case 'noticia1':
        return <CardNews data={data} />
      case 'noticia2':
        return <CardNews2 data={data} />
      case 'noticia3':
        return <CardNews3 data={data} />
      case 'noticia4':
        return <CardNews4 data={data} />
      case 'noticia6':
        return <CardNews2 data={data} />
      default:
        return
    }
  }

  const renderNews = useCallback(() => {
    return news.latest.length ? (
      news.latest.length > 1 ? (
        news.latest
          .filter((n, i) => i < limit)
          .map((data, key) => {
            let cmp
            if (key === parseInt(0)) {
              cmp = (
                <Grid
                  style={{ flex: data.newStyle === 'noticia4' ? 2 : 1 }}
                  key={key}
                  item
                  className={`${classes.carouselCard} ${classes.sectCarouselCard}`}
                >
                  {conditionalRenderNews(data)}
                </Grid>
              )
            } else if (key === parseInt(1)) {
              cmp = (
                <Grid
                  style={{ flex: data.newStyle === 'noticia4' ? 2 : 1 }}
                  key={key}
                  item
                  className={`${classes.carouselCard} ${classes.sectCarouselCard}`}
                >
                  {conditionalRenderNews(data)}
                </Grid>
              )
            } else if (key === parseInt(2)) {
              cmp = (
                <Grid
                  style={{ flex: data.newStyle === 'noticia4' ? 2 : 1 }}
                  key={key}
                  item
                  className={`${classes.carouselCard} ${classes.sectCarouselCard}`}
                >
                  {conditionalRenderNews(data)}
                </Grid>
              )
            } else if (key === parseInt(3)) {
              cmp = (
                <Grid
                  style={{ flex: data.newStyle === 'noticia4' ? 2 : 1 }}
                  key={key}
                  item
                  className={`${classes.carouselCard} ${classes.sectCarouselCard}`}
                >
                  {conditionalRenderNews(data)}
                </Grid>
              )
            }
            return cmp
          })
      ) : (
        news.latest.map((data, key) => {
          return (
            <Grid
              style={{ flex: data.newStyle === 'noticia4' ? 2 : 1 }}
              key={key}
              item
              className={`${classes.carouselCard} ${classes.sectCarouselCard}`}
            >
              {conditionalRenderNews(data)}
            </Grid>
          )
        })
      )
    ) : (
      <Fragment>
        <Grid
          item
          xs={12}
          md={5}
          className={`${classes.carouselCard} ${classes.sectCarouselCard}`}
        >
          <CardNotNews />
        </Grid>
        <Grid
          item
          xs={12}
          md={3}
          className={`${classes.carouselCard} ${classes.sectCarouselCard}`}
        >
          <CardNotNews />
        </Grid>
      </Fragment>
    )
  }, [classes, news.latest])

  useEffect(() => {
    if (token) {
      if (!news.latest.length > 0) {
        executeInitnews()
      }
    }
  }, [token])
  return (
    <Grid className={classes.container}>
      <Grid container className={classes.root}>
        <Hidden xsDown>{renderNews()}</Hidden>

        <Hidden smUp>
          <Grid
            item
            xs={12}
            sm={6}
            md={4}
            lg={6}
            className={classes.carouselNews}
          >
            {news.latest.length && (
              <Carousel
                addArrowClickHandler={true}
                lazyload
                centered
                arrowLeft={<ArrowBackIos />}
                arrowRight={<ArrowForwardIos />}
              >
                {news.latest
                  .filter((n, i) => i < limit)
                  .map((data, key) => (
                    <Grid
                      item
                      key={key}
                      xs={12}
                      sm={12}
                      md={4}
                      lg={6}
                      className={classes.paddingNews}
                    >
                      {conditionalRenderNews(data)}
                    </Grid>
                  ))}
              </Carousel>
            )}
          </Grid>
        </Hidden>
      </Grid>
    </Grid>
  )
}

const mapStateToProps = (state) => {
  return {
    news: state.news
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getLatestNews: (url, token, query) =>
      dispatch(getBriefsNews(url, token, query))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Latest)
