import React from 'react';
import { Grid } from '@material-ui/core'
import { useStyles } from './styles';

const CardNotNews = () => {
    const classes = useStyles();

    return (
        <Grid container item xs={12} className={classes.sectCardNotNews}>
                No hay noticias
        </Grid>
    );
}
 
export default CardNotNews;