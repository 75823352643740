import { Grid, makeStyles, Typography } from '@material-ui/core';
import React from 'react'
import { connect } from 'react-redux';
import Filter from './components/filter';
import Latest from './components/latest';
import More from './components/more';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: '1rem',
    backgroundColor: '#fff',
    borderRadius: 15,
    '& hr': {
      border: 'none',
      borderBottom: '1px solid rgba(0, 0, 0, 0.1)'
    }
  },
  sectionTitle: {
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: 24,
    letterSpacing: 0.15,
    color: '#141B6A'
  },
  content: {
    padding: '1rem',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column-reverse'
    },
  }
}));

const App = ({ token, url, query }) => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Typography className={classes.sectionTitle}>
        Ultimas noticias
      </Typography>
      <div className={classes.content}>
        <Latest token={token} limit={3} url={url} query={query} />
      </div>
      <hr />
      <Typography className={classes.sectionTitle}>
        Más noticias
      </Typography>
      <Grid container className={classes.content}>
        <Grid item md={10}>
          <More token={token} skip={3} url={url}  query={query} />
        </Grid>
        <Grid item md={2}>
          <Filter />
        </Grid>
      </Grid>
    </div>
  )
};

export default App;
