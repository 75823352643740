(function () {
  const template = document.createElement('template');
  template.innerHTML = `
    <style>
      :host {
        display: flex;
        flex-direction: column;
        width: 100%;
        contain: content;
        border-top-right-radius : 5px;
        border-top-left-radius : 5px;
        background-color: #EFEFEF;
      }

      :host #sise-tabs {
        width: 100%;
      }

      :host([vertical]) {
        flex-direction: row;
      }

      :host([vertical]) #sise-tabs {
        width: auto;
      }

      :host([vertical]) #sise-tabs slot {
        flex-direction: column;
      }

      #sise-panels {
        box-shadow: 0 2px 2px rgba(0, 0, 0, .3) !important;
        background-color: #EFEFEF !important;
        border-radius: 3px !important;
        padding: 16px !important;
        height: auto !important;
        overflow: auto !important;
        transition: 0.5s;
      }

      :host([vertical]) #sise-panels {
        width: 100%;
      }

      #sise-panels ::slotted(*) {
        outline: 0 !important;
      }

      #sise-tabs {
        display: inline-flex !important;
        -webkit-user-select: none !important;
        user-select: none !important;

      }
      #sise-tabs slot {
        display: inline-flex !important; /* Safari bug. Treats <slot> as a parent */
        width: 100% !important;
      }
      /* Safari does not support #id prefixes on ::slotted
        See https://bugs.webkit.org/show_bug.cgi?id=160538 */
      #sise-tabs ::slotted(*) {
        font-size: 14px !important;
        font-family: Roboto, sans-serif !important;
        padding: 8px !important;
        margin: 0 !important;
        text-align: center !important;
        text-overflow: ellipsis !important;
        white-space: nowrap !important;
        overflow: hidden !important;
        cursor: pointer !important;
        background-color: #DDDDDD !important;
        border: none !important; /* if the user users a <button> */
        flex: 1 !important;
        outline: 0 !important;
        max-height: 33px !important;
      }
      #sise-tabs ::slotted([aria-selected="true"]) {
        font-weight: 600 !important;
        background-color: #D1D1D1 !important;
        box-shadow: none !important;
      }
      #sise-tabs ::slotted(:focus) {
        z-index: 1 !important; /* make sure focus ring doesn't get buried */
      }
      #sise-panels ::slotted([aria-hidden="true"]) {
        display: none !important;
        transition: 0.5s;
      }
    </style>
    <div id="sise-tabs">
      <slot id="tabsSlot" name="title"></slot>
    </div>
    <div id="sise-panels">
      <slot id="panelsSlot"></slot>
    </div>
  `;

  let selected_ = null;

  class Tabs extends HTMLElement {
    constructor() {
      super();

      this.attachShadow({ mode: 'open' });
      this.shadowRoot.appendChild(template.content.cloneNode(true));
      this.childNodes.forEach((child) => {
        if (child.nodeType === 1) {
          const button = document.createElement('button');
          button.setAttribute('slot', 'title');
          button.innerHTML = child.title;
          this.appendChild(button);
          const section = document.createElement('section');
          section.innerHTML = child.innerHTML;
          this.appendChild(section);
        }
      });
    }

    get selected() {
      return selected_;
    }

    set selected(idx) {
      selected_ = idx;

      this.selectTab(idx);

      this.setAttribute('selected', idx);
    }

    connectedCallback() {
      this.setAttribute('role', 'tablist');
      const tabsSlot = this.shadowRoot.querySelector('#tabsSlot');
      const panelsSlot = this.shadowRoot.querySelector('#panelsSlot');
      this.tabs = tabsSlot.assignedNodes({ flatten: true });
      this.panels = panelsSlot
        .assignedNodes({ flatten: true })
        .filter((el) => {
          return el.nodeType === Node.ELEMENT_NODE;
        })
        .filter((el) => {
          return el.nodeName === 'SECTION';
        });

      // Add aria role="tabpanel" to each content panel.
      for (const panel of this.panels.entries()) {
        panel[1].setAttribute('role', 'tabpanel');
        panel[1].setAttribute('tabindex', 0);
      }

      // Save refer to we can remove listeners later.
      this._boundOnTitleClick = this._onTitleClick.bind(this);
      tabsSlot.addEventListener('click', this._boundOnTitleClick);

      this.selected = this._findFirstSelectedTab() || 0;
    }

    disconnectedCallback() {
      const tabsSlot = this.shadowRoot.querySelector('#tabsSlot');
      tabsSlot.removeEventListener('click', this._boundOnTitleClick);
    }

    _onTitleClick(e) {
      if (e.target.slot === 'title') {
        this.selected = this.tabs.indexOf(e.target);
        e.target.focus();
      }
    }

    _findFirstSelectedTab() {
      let selectedIdx;
      for (const [i, tab] of this.tabs.entries()) {
        tab.setAttribute('role', 'tab');
        // Allow users to declaratively select a tab
        // Highlight last tab which has the selected attribute.
        if (tab.hasAttribute('selected')) {
          selectedIdx = i;
        }
      }
      return selectedIdx;
    }

    selectTab(idx = null) {
      for (let i = 0, tab; (tab = this.tabs[i]); ++i) {
        const select = i === idx;
        tab.setAttribute('tabindex', select ? 0 : -1);
        tab.setAttribute('aria-selected', select);
        this.panels[i].setAttribute('aria-hidden', !select);
      }
    }
  }
  customElements.define('sise-tabs', Tabs);

  class Tab extends HTMLElement {
    connectedCallback() {
      this.remove();
    }
  }
  customElements.define('sise-tab', Tab);
}());
