import { observable, runInAction } from 'mobx';
import Utils from '../../../../utils';

class ConfigExecuteStore {
  @observable queries = [];
  @observable navItems = [];
  @observable title = '';
  @observable description = '';
  @observable menuName = '';
  @observable editableMenuItem = { index: -1, value: '' };
  @observable selectedQuery = null;
  @observable tabIndex = 0;
  @observable navIndex = 0;
  @observable retryPreview = false;
  @observable results = null;
  @observable globalResults = null;
  @observable sharedData = null;
  @observable openNav = true;
  @observable fetching = false;

  constructor(qSiseService, loaderStore, snackBarStore) {
    this.qSiseService = qSiseService;
    this.loaderStore = loaderStore;
    this.snackBarStore = snackBarStore;
  }

  initialize = (obj) => {
    runInAction(() => {
      this.title = obj.title;
      this.description = obj.description;
      this.navItems = obj.menu;
    });
  };

  addMenuItem = () => {
    if (this.menuName !== '') {
      runInAction(() => {
        this.navItems.push({
          title: this.menuName,
          template: '',
          data: [],
          script: { local: '', global: '' }
        });
        this.menuName = '';
      });
    } else {
      this.snackBarStore.setMessage('Ingrese un valor para el nombre del menú');
    }
  };

  removeMenuItem = (index) => {
    if (this.navIndex === index && this.navIndex > 0) {
      this.navIndex--;
    } else if (this.navIndex === 0) {
      this.navIndex = 0;
    }
    this.navItems = this.navItems.filter((_, i) => {
      return i !== index;
    });
  };

  moveMenuItem = ({ oldIndex, newIndex }) => {
    this.navItems = Utils.arrayMove(this.navItems, oldIndex, newIndex);
  }

  enableEditableMenuItem = (i) => {
    this.editableMenuItem = { index: i, value: this.navItems[i].title };
  };

  disableEditableMenuItem = () => {
    this.editableMenuItem = { index: -1, value: '' };
  };

  editMenuItem = () => {
    runInAction(() => {
      this.navItems[
        this.editableMenuItem.index
      ].title = this.editableMenuItem.value;
      this.disableEditableMenuItem();
    });
  };

  changeNavIndex = (index) => {
    runInAction(() => {
      this.navIndex = index;
      this.tabIndex = 0;
      this.retryPreview = true;
    });
  };

  addQuery = (query) => {
    runInAction(() => {
      this.selectedQuery = null;
      this.navItems[this.navIndex].data.push(query);
      this.retryPreview = true;
    });
  };

  removeQuery = (index) => {
    runInAction(() => {
      this.selectedQuery = null;
      this.navItems[this.navIndex].data = this.navItems[
        this.navIndex
      ].data.filter((_, i) => {
        return i !== index;
      });
      this.retryPreview = true;
    });
  };

  updateQueryProperties = (dataset) => {
    runInAction(() => {
      this.navItems[this.navIndex].data
        .filter((ds) => {
          return ds.query.id === dataset.query.id;
        })
        .map((ds) => {
          const updatedDataset = ds;
          updatedDataset.criteriaFilter = dataset.criteriaFilter;
          updatedDataset.parameters = dataset.parameters;
          return updatedDataset;
        });
      this.retryPreview = true;
    });
  };

  updateQueryNickname = (value, id) => {
    runInAction(() => {
      this.retryPreview = true;
      this.navItems[this.navIndex].data.map((q) => {
        const dataset = q;
        if (dataset.query.id === id) {
          dataset.nickname = value;
        }
        return dataset;
      });
    });
  };

  changeTemplate = (value) => {
    runInAction(() => {
      this.navItems[this.navIndex].template = value;
    });
  };

  changeScript = (key, value) => {
    runInAction(() => {
      this.navItems[this.navIndex].script[key] = value;
    });
  };

  executeQueries = (queries) => {
    const formattedResult = {};
    return new Promise((resolve, reject) => {
      runInAction(() => {
        this.loaderStore.start();
        this.results = null;
        this.fetching = true;
      });
      this.qSiseService
        .executeQueries(queries)
        .then((response) => {
          //console.log(response);
          Object.keys(response).forEach((key) => {
            formattedResult[key] = response[key].result.slice();
            // if (response[key].result.length > 1) {
            // } else {
            //   formattedResult[key] = response[key].result[0];
            // }
          });
          runInAction(() => {
            this.globalResults = { ...this.globalResults, ...formattedResult };
            this.results = formattedResult;
            this.fetching = false;
            this.loaderStore.end();
            resolve(formattedResult);
          });
        })
        .catch((error) => {
          this.fetching = false;
          this.loaderStore.end();
          this.snackBarStore.setMessage(error);
          reject(error);
        });
    });
  };

  saveQsise = (body) => {
    return new Promise((resolve, reject) => {
      runInAction(() => {
        this.loaderStore.start();
      });
      this.qSiseService
        .save(body)
        .then((response) => {
          this.loaderStore.end();
          resolve(response);
        })
        .catch((error) => {
          this.loaderStore.end();
          this.snackBarStore.setMessage(error);
          reject(error);
        });
    });
  };

  updateQsise = (body) => {
    return new Promise((resolve, reject) => {
      runInAction(() => {
        this.loaderStore.start();
      });
      this.qSiseService
        .update(body)
        .then((response) => {
          this.loaderStore.end();
          resolve(response);
        })
        .catch((error) => {
          this.loaderStore.end();
          this.snackBarStore.setMessage(error);
          reject(error);
        });
    });
  };

  updateSharedData = (data) => {
    this.sharedData = {
      ...this.sharedData,
      ...data
    };
  };
}

export default ConfigExecuteStore;
