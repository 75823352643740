import 'whatwg-fetch';
import checkStatus, { getToken, checkStatusWithFullErrorResponse } from './fetchHelpers';

class QsiseService {

  constructor(getUrl, saveUrl, updateUrl, deleteUrl, executeUrl, getByIdUrl) {
    this.getUrl = getUrl;
    this.saveUrl = saveUrl;
    this.updateUrl = updateUrl;
    this.deleteUrl = deleteUrl;
    this.executeUrl = executeUrl;
    this.getByIdUrl = getByIdUrl;
  }

  fetch() {
    return new Promise((resolve, reject) => {
      fetch(this.getUrl, {
        credentials: 'same-origin',
        headers: { Accept: 'application/json', 'Content-Type': 'application/json', Authorization: `Bearer ${getToken()}` }
      }).then(checkStatus)
        .then((response) => {
          return response.json();
        }).then((json) => {
          resolve(json);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  executeQueries = (queries) => {
    return new Promise((resolve, reject) => {
      fetch(this.executeUrl, {
        credentials: 'same-origin',
        method: 'POST',
        headers: { Accept: 'application/json', 'Content-Type': 'application/json', Authorization: `Bearer ${getToken()}` },
        body: JSON.stringify(queries)
      }).then(checkStatus)
        .then((response) => {
          return response.json();
        }).then((json) => {
          resolve(json);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  get = (qSiseId) => {
    return new Promise((resolve, reject) => {
      fetch(`${this.getByIdUrl}/${qSiseId}`, {
        credentials: 'same-origin',
        headers: { Accept: 'application/json', 'Content-Type': 'application/json', Authorization: `Bearer ${getToken()}` },
      }).then(checkStatus)
        .then((response) => {
          return response.json();
        }).then((json) => {
          resolve(json);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  save = (body) => {
    return new Promise((resolve, reject) => {
      fetch(this.saveUrl, {
        credentials: 'same-origin',
        method: 'POST',
        headers: { Accept: 'application/json', 'Content-Type': 'application/json', Authorization: `Bearer ${getToken()}` },
        body: JSON.stringify(body)
      }).then(checkStatus)
        .then((response) => {
          return response.json();
        }).then((json) => {
          resolve(json);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  update = (body) => {
    return new Promise((resolve, reject) => {
      fetch(this.updateUrl, {
        credentials: 'same-origin',
        method: 'POST',
        headers: { Accept: 'application/json', 'Content-Type': 'application/json', Authorization: `Bearer ${getToken()}` },
        body: JSON.stringify(body)
      }).then(checkStatus)
        .then((response) => {
          return response.json();
        }).then((json) => {
          resolve(json);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  delete = (body) => {
    return new Promise((resolve, reject) => {
      fetch(`${this.deleteUrl}/${body.id}`, {
        credentials: 'same-origin',
        method: 'DELETE',
        headers: { Accept: 'application/json', 'Content-Type': 'application/json', Authorization: `Bearer ${getToken()}` }
      })
      .then(checkStatusWithFullErrorResponse)
      .then((data) => {
        return new Promise((res) => {
          data.json()
            .then(() => { res(); })
            .catch(() => { res(); });
        });
      })
      .then(() => {
        resolve();
      })
      .catch((error) => {
        reject(error);
      });
    });
  }
}

export default QsiseService;
