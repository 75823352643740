import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: 6,
    [theme.breakpoints.down('xs')]: {
      marginTop: 0
    }
  },
  banner: {
    marginTop: '7px',
    overflow: 'hidden',
    backgroundColor: '#E0E0E0',
    maxHeight: 279,
    marginBottom: 60,
    [theme.breakpoints.down('xs')]: {
      marginTop: 0,
      maxHeight: 239,
      marginBottom: 44
    },
    '& .swiper-pagination-bullet:not(.swiper-pagination-bullet-active)': {
      opacity: 1,
      background: '#FFF'
    }
  },
  infoBannerDefault: {
    height: 279,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    justifyContent: 'center',
    [theme.breakpoints.down('xs')]: {
      height: 239
    }
  },
  infoBanner: {
    overflow: 'hidden',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    height: 279,
    width: '100vw',
    paddingBottom: 80,
    paddingLeft: 113,
    [theme.breakpoints.down('xs')]: {
      paddingLeft: 25,
      paddingBottom: 65,
      height: 239
    }
  },
  sectSeeMore: {
    backgroundColor: theme.palette.primary?.main,
    height: 40,
    width: 150,
    borderRadius: 5,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    alignContent: 'center',
    marginTop: 20,
    color: '#FFFFFF'
  }
}))
