import { Grid } from '@material-ui/core'
import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { getNews } from '../../actions/newsActions'

import { useStyles } from './styles'
import CardNews from '../cardNews'
import CardNews2 from '../cardNews2'
import CardNews3 from '../cardNews3'
import CardNews4 from '../cardNews4'
import CardNotNews from '../cardNotNews'

const More = ({ token, news, skip, getMoreNews, url, query }) => {
  const classes = useStyles()

  const executeInitnews = async () => {
    await getMoreNews(url, token, query)
  }

  const conditionalRenderNews = (data) => {
    switch (data.newStyle) {
      case 'noticia1':
        return <CardNews data={data} />
      case 'noticia2':
        return <CardNews2 data={data} />
      case 'noticia3':
        return <CardNews3 data={data} />
      case 'noticia4':
        return <CardNews4 data={data} />
      case 'noticia6':
        return <CardNews2 data={data} />
      default:
        return
    }
  }

  useEffect(() => {
    if (token) {
      if (!news.data.length > 0) {
        executeInitnews()
      }
    }
  }, [token])

  const excludeLatestNews = () => {
    return news.filteredData.filter(
      (n) => !news.latest.find((l, i) => i < skip && l._id === n._id)
    )
  }
  return (
    <Grid className={classes.container}>
      <Grid container className={classes.root}>
        {excludeLatestNews().map((data, key) => (
          <Grid
            item
            key={key}
            xs={12}
            sm={12}
            md={4}
            lg={data.newStyle === 'noticia4' ? 12 : 4}
            className={classes.paddingNews}
          >
            {conditionalRenderNews(data)}
          </Grid>
        ))}
      </Grid>
    </Grid>
  )
}

const mapStateToProps = (state) => {
  return {
    news: state.news
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getMoreNews: (url, token, query) => dispatch(getNews(url, token, query))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(More)
