(function () {
  const template = document.createElement('template');
  template.innerHTML = `
    <style>
    .tick-slider {
      position: relative;
  
      width: 100%;
      pointer-events: none;
      z-index: 10;
    }
    
    .tick-slider-background,
    .tick-slider-progress,
    .tick-slider-tick-container {
        position: absolute;
        bottom: 5px;
        left: 0;
    
        height: 5px;
    
        pointer-events: none;
    
        border-radius: 3px;
    
        z-index: -1;
    }
    
    .tick-slider-background {
        width: 100%;
    }

    .tick-slider-progress.completed {
      background-color: #6FCF97;
    }
    
    .tick-slider-tick-container {
        width: 100%;
    
        display: flex;
        justify-content: space-between;
        align-items: center;
    
        padding: 0 calc(18 / 2);
    }
    
    .tick-slider-tick {
        width: 2px;
        height: 2px;
    
        border-radius: 50%;
    
        background-color: white;
    }
    
    .tick-slider-label {
        opacity: 0.85;
        transition: opacity 0.1s ease;
    }
    
    .tick-slider-label.hidden {
        opacity: 0;
    }
    
    /*
    
        REMOVE SLIDER STYLE DEFAULTS
    
    */
    input[type="range"] {
        -webkit-appearance: none;
    
        width: 100%;
        height: 100%;
    
        background: transparent;
        outline: none;
    
        margin: 5px 0;
    }
    
    input[type="range"]::-webkit-slider-thumb {
        -webkit-appearance: none;
    
        border: none;
    }
    
    input[type="range"]:focus {
        outline: none;
    }
    
    input[type="range"]::-moz-focus-outer {
        border: 0;
    }
    
    /*
    
        HANDLE
    
    */
    input[type="range"]::-webkit-slider-thumb {
        -webkit-appearance: none;
    }
    
    input[type="range"]:hover::-webkit-slider-thumb,
    input[type="range"]:focus::-webkit-slider-thumb {
        transform: scale(1.2);
    }
    
    input[type="range"]::-moz-range-thumb {
        -webkit-appearance: none;
    }
    
    input[type="range"]:hover::-moz-range-thumb,
    input[type="range"]:focus::-moz-range-thumb {
        transform: scale(1.2);
    }
    
    /*
    
        TRACK
    
    */
    
    input[type="range"]::-webkit-slider-runnable-track {
        width: 100%;
        height: var(--slider-track-height);
    
        cursor: pointer;
    
        background: none;
    
        border-radius: var(--slider-track-border-radius);
    }
    
    input[type="range"]::-moz-range-track {
        width: 100%;
        height: var(--slider-track-height);
    
        cursor: pointer;
    
        background: none;
    
        border-radius: var(--slider-track-border-radius);
    }
    
    input[type="range"]:focus::-webkit-slider-runnable-track {
        background: none;
    }
    input[type="range"]:active::-webkit-slider-runnable-track {
        background: none;
    }
    </style>
  
    <div class="tick-slider">
      <div class="tick-slider-value-container">
          <div id="weightLabelMin" class="tick-slider-label"></div>
          <div id="weightLabelMax" class="tick-slider-label"></div>
          <div id="weightValue" class="tick-slider-value"></div>
      </div>
      <div class="tick-slider-background"></div>
      <div id="weightProgress" class="tick-slider-progress"></div>
      <div id="weightTicks" class="tick-slider-tick-container"></div>
      <input
          id="weightSlider"
          class="tick-slider-input"
          type="range"
          min="0"
          max="10"
          step="1"
          value="9"
          data-tick-step="1"
          data-tick-id="weightTicks"
          data-value-id="weightValue"
          data-progress-id="weightProgress"
          data-handle-size="18"
          data-min-label-id="weightLabelMin"
          data-max-label-id="weightLabelMax"
      />
    </div>
  `;

  class Range extends HTMLElement {
    constructor() {
      super();
      this.attachShadow({ mode: 'open' })
        .appendChild(template.content.cloneNode(true));

      this.min = Number(this.getAttribute('min')) || 0;
      this.max = Number(this.getAttribute('max')) || 10;
      this.value = Number(this.getAttribute('value')) || 0;
      this.color = this.getAttribute('color') || '#6716F5';
      this.bgColor = this.getAttribute('bgColor') || '#D8D0F6';
    }

    static get observedAttributes() {
      return ['value'];
    }

    attributeChangedCallback(name, oldValue, newValue) {
      this.input = this.shadowRoot.querySelector('input[type="range"].tick-slider-input');
      this.input.setAttribute('value', newValue);
      this.updateValuePosition(this.input);
      this.updateProgress(this.input);
      this.setTicks(this.input);
    }

    get value() {
      return this.getAttribute('value');
    }
    
    set value(newValue) {
      this.setAttribute('value', newValue);
    }

    connectedCallback() {
      this.input = this.shadowRoot.querySelector('input[type="range"].tick-slider-input');
      this.wrapper = this.shadowRoot.getElementById('wrapper');
      this.button = this.shadowRoot.querySelector('button');
      this.input.setAttribute('min', this.min);
      this.input.setAttribute('max', this.max);
      this.input.setAttribute('value', this.value);
      const progress = this.shadowRoot.getElementById(this.input.dataset.progressId);
      progress.style.backgroundColor = this.color;
      const progressBg = this.shadowRoot.querySelector('.tick-slider-background');
      progressBg.style.backgroundColor = this.bgColor;
      this.updateValuePosition(this.input);
      this.updateProgress(this.input);
      this.setTicks(this.input);
    }

    updateValuePosition(slider) {
      const value = this.shadowRoot.getElementById(slider.dataset.valueId);

      const percent = this.getSliderPercent(slider);

      const sliderWidth = slider.getBoundingClientRect().width;
      const valueWidth = value.getBoundingClientRect().width;
      const handleSize = slider.dataset.handleSize;

      let left = percent * (sliderWidth - handleSize) + handleSize / 2 - valueWidth / 2;

      left = Math.min(left, sliderWidth - valueWidth);
      left = slider.value === slider.min ? 0 : left;

      value.style.left = `${left}px`;
    }

    updateProgress(slider) {
      const progress = this.shadowRoot.getElementById(slider.dataset.progressId);
      const percent = this.getSliderPercent(slider);
      if (slider.value === slider.max) {
        progress.classList.add('completed');
      } else {
        progress.classList.remove('completed');
      }
      progress.style.width = `${percent * 100}%`;
    }

    getSliderPercent(slider) {
      const range = slider.max - slider.min;
      const absValue = slider.value - slider.min;

      return absValue / range;
    }

    setTicks(slider) {
      const container = this.shadowRoot.getElementById(slider.dataset.tickId);
      const spacing = parseFloat(slider.dataset.tickStep);
      const sliderRange = slider.max - slider.min;
      const tickCount = sliderRange / spacing + 1; // +1 to account for 0

      for (let ii = 0; ii < tickCount; ii++) {
        const tick = document.createElement('span');

        tick.className = 'tick-slider-tick';

        container.appendChild(tick);
      }
    }
  }
  customElements.define('sise-range', Range);
}());
