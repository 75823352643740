import { createAsyncAction } from 'redux-promise-middleware-actions'
import {
  quotationUrlApi,
  validateResponse,
  exeptionCodeResponse
} from '../utils/urls'

export const getNewsAPI = createAsyncAction(
  'GET_NEWS',
  async (url, token, query) => {
    const headers = {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json'
    }
    const params = new URLSearchParams(query)
    const res = await fetch(`${url}v1/new/brief/more?${params}`, { headers })
      .then((response) => validateResponse(response))
      .catch((error) => {
        throw exeptionCodeResponse(error)
      })
    return res
  }
)

export const getBriefsNewsAPI = createAsyncAction(
  'GET_LATEST_NEWS',
  async (url, token, query) => {
    const headers = {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`
    }
    const params = new URLSearchParams(query)
    const res = await fetch(`${url}v1/new/brief/current?${params}`, {
      headers
    })
      .then((response) => validateResponse(response))
      .catch((error) => {
        throw exeptionCodeResponse(error)
      })
    return res
  }
)

export const getNewBySlugAPI = createAsyncAction(
  'GET_NEWS_BY_SLUG',
  async (url, slug) => {
    const headers = {
      'Content-Type': 'application/json'
    }
    const res = await fetch(`${url}v1/new/slug/${slug}`, { headers })
      .then((response) => validateResponse(response))
      .catch((error) => {
        throw exeptionCodeResponse(error)
      })
    return res
  }
)
