import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => ({
    sectCardNotNews: {
        position: 'relative',
        backgroundColor: 'rgba(33, 33, 33, 0.08)',
        height: 359,
        borderRadius: 18,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    sectCarouselCard: {
        paddingBottom: 20,
        height: '100%'
    },
    sectCarouselCard2: {
        paddingBottom: 20,
        height: '100%'
    },
}))