import { observable, runInAction } from 'mobx';

class DetailsNavigatorStore {
  @observable outerActiveIndex = 0;
  @observable innerActiveIndex = 0;
  @observable datasets = [];

  getInnerNavigators = (items, index) => {
    if (index) {
      return items.length > 0 ? items[this.outerActiveIndex].menu[index] : {};
    }
    return items.length > 0 ? items[this.outerActiveIndex].menu : [];
  };

  getActiveInnerNavigator = (items) => {
    return items.length > 0
      ? items[this.outerActiveIndex].menu[this.innerActiveIndex]
      : [];
  };

  initializeDatasets = (items, data) => {
    const newDatasets = items.map((_i, i) => {
      if (this.outerActiveIndex === i) {
        const dS = {
          results: _i.menu.map((_m, j) => {
            let innerData = null;
            if (this.innerActiveIndex === j) {
              innerData = data;
            }
            return innerData;
          })
        };
        return dS;
      }

      return {
        results: _i.menu.map(() => {
          return null;
        })
      };
    });
    this.datasets = newDatasets;
  };

  getActiveDataSet = () => {
    return this.datasets.length > 0
      ? this.datasets[this.outerActiveIndex].results[this.innerActiveIndex]
      : {};
  };

  getDataSetByIndex = (index) => {
    return this.datasets[this.outerActiveIndex].results[index];
  };

  setDataSet = (data) => {
    this.datasets[this.outerActiveIndex].results[this.innerActiveIndex] = data;
  };

  changeArea = (index) => {
    runInAction(() => {
      this.outerActiveIndex = index;
      this.innerActiveIndex = 0;
    });
  };

  changeTab = (index) => {
    this.innerActiveIndex = index;
  };
}

export default DetailsNavigatorStore;
