import React from 'react';
import { Grid, Typography, Hidden, Link, Chip } from '@material-ui/core';
import { useStyles } from './styles';
import Utils from '../../utils/utils';
import { Launch } from '@material-ui/icons';
import moment from 'moment';

const CardNews4 = ({ data }) => {
    const classes = useStyles();
    return (
        <Grid container className={classes.sectCardNews}>
            <Grid item xs={6} className={classes.sectImage}>
                <img className="image" src={data.image} />
            </Grid>
            <Grid item xs={6}>
                <Grid container className={classes.root}>
                    <Grid container item justify="space-between" alignItems="center">
                        <Grid item className={classes.newsTagContainer}>
                            {data.tags && data.tags.split(',').map(t => <Chip className={classes.newsTag} label={t} />)}
                        </Grid>
                        <Grid item className={classes.dateContainer}>
                            {moment(data.createdAt).format('DD MMM YYYY')}
                        </Grid>
                    </Grid>
                    <Grid className={classes.sectText}>
                        <Typography className="title">
                            {data.title}
                        </Typography>
                        <Typography className="extract">
                            {Utils.getBriefText(data.extractFull)}
                        </Typography>
                    </Grid>
                    <Grid
                        className={classes.sectActions}
                        direction="row"
                        justify="space-between"
                        alignItems="center"
                    >
                        <Typography className="title">
                            <Link href={Utils.getUrl(data.slug)}>
                                LEER MÁS
                            </Link>
                        </Typography>
                        <Launch />
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
}

export default CardNews4;