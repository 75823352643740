import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
    root: {
        paddingTop: 30,
        '& > div': {
          margin: '16px 0'
        },
        [theme.breakpoints.down('xs')]: {
            paddingTop: 0
        },
    },
    container: {
        [theme.breakpoints.down("sm")]: {
            paddingBottom: '3rem',
        },
    },
    carouselCard: {
        [theme.breakpoints.down('sm')]: {
            height: '250px'
        },
        [theme.breakpoints.down('xs')]: {
            height: '350px'
        },
    },
    carouselNews: {
        height: '100%',
        '& ul': {
            height: 440
        }
    },
    cardNotNews: {
        backgroundColor: '#D5D5D5',
        height: '100%',
        borderRadius: 20,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    sectCarouselCard: {
        paddingBottom: 20,
        padding: '0px 20px 20px 0px',
        height: '100%',
        [theme.breakpoints.down('sm')]: {
            padding: 0
        }
    },
    sectCarouselCard2: {
        paddingBottom: 20,
        padding: '0px 0px 20px 0px',
        height: '100%',
        [theme.breakpoints.down('sm')]: {
            padding: 0
        }
    },
    sectCarouselCardNotNews: {
        paddingBottom: 20,
        padding: '0px 20px 20px 0px',
        [theme.breakpoints.down('sm')]: {
            padding: 0
        }
    },
    sectCarouselCardNotNews2: {
        paddingBottom: 20,
        padding: '0px 0px 20px 0px',
        [theme.breakpoints.down('sm')]: {
            padding: 0
        }
    },
    paddingNews : {
        padding: '0px 20px 0px 20px',
        [theme.breakpoints.down('sm')]: {
            padding: 0
        }
    }
}))