import { Chip, Grid } from '@material-ui/core';
import ReactHtmlParser from "react-html-parser";
import React, { Fragment, useEffect } from 'react';
import { useStyles } from './styles'
import moment from 'moment';
import { connect } from 'react-redux';
import { getNewBySlug } from '../../actions/newsActions';
import LayoutLoading from '../layout/layoutLoading';

const Reader = ({ news, slug, getNew, url }) => {
  const classes = useStyles();

  useEffect(() => {
    executeInitnew();
    window.scrollTo(0, 0)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  const executeInitnew = async () => {
    if (slug) {
      console.log(slug);
      await getNew(url, slug);
    }
  };


  const isEmpty = (obj) => {
    return Object.keys(obj).length === 0;
  }

  return (
    <Fragment>
      <Grid container justify="space-around" alignItems="center" className={classes.containerCardsSection1}>
        <Grid item xs={12} sm={12} className={classes.mainOverview}>
          {
            !news.loading ? ReactHtmlParser(news.selected.content) : <LayoutLoading />
          }
          <br />
          <span><strong>Publicado:</strong> {moment(news.selected.updatedAt).format('YYYY-MM-DD')}</span>
          <Grid container className={classes.containerChips}>
            {
              !isEmpty(news.selected) && news.selected.tags && news.selected.tags.split(",").map((item, index) => <Chip
                key={`${item}-${index}`}
                label={item}
                style={{ marginRight: 4 }}
                color="primary"
              />)
            }
          </Grid>

        </Grid>

      </Grid>
    </Fragment>
  )
};

const mapStateToProps = state => {
  return {
    news: state.news
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getNew: (url, slug) => dispatch(getNewBySlug(url, slug))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Reader);
