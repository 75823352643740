import React, { useState, Fragment } from 'react';
import { Grid, Typography, Hidden, Link, Chip } from '@material-ui/core';
import Utils from '../../utils/utils';
import { useStyles } from './styles';
import { Launch } from '@material-ui/icons';
import moment from 'moment';

const CardNews3 = ({ data }) => {
    const classes = useStyles();
    const [hoverCard, setHoverCard] = useState(false)

    const handleHoverCard = (option) => {
        setHoverCard(option)
    }

    return (
        <Grid container className={classes.sectCardNews}>
            {hoverCard ? (
                <Grid item xs={12} className={!hoverCard ? classes.sectImage : classes.sectImageFull} style={{ backgroundImage: `url(${data.image})` }} />
            ) : (
                <Grid item xs={12} className={!hoverCard ? classes.sectImage : classes.sectImageFull}>
                    <img className={classes.imgNews} src={data.image} />
                </Grid>
            )}
            <Hidden smUp>
                <a href={Utils.getUrl(data.slug)}>
                    <Launch className={classes.iconLinkMobileTop} />
                </a>
            </Hidden>
            <Grid item xs={12} className={hoverCard ? classes.heightSectImageFull : null}>
                <Grid container className={classes.root}
                    onMouseEnter={() => handleHoverCard(true)}
                    onMouseLeave={() => handleHoverCard(false)}
                >
                    <Grid container className={classes.gradient} />
                    <Grid container item justify="space-between" alignItems="center">
                        <Grid item className={classes.newsTagContainer}>
                            {data.tags && data.tags.split(',').map(t => <Chip className={classes.newsTag} label={t} />)}
                        </Grid>
                        <Grid item className={classes.dateContainer}>
                            {moment(data.createdAt).format('DD MMM YYYY')}
                        </Grid>
                    </Grid>
                    <Grid item lg={!hoverCard ? 10 : 12} md={!hoverCard ? 10 : 12} sm={!hoverCard ? 10 : 12} xs={!hoverCard ? 10 : 12} className={classes.sectText}>
                        <Typography className='title'>
                            {data.title}
                        </Typography>
                        <Typography className='extract'>
                            {hoverCard ? (
                                <Fragment>
                                    <Hidden mdDown>
                                        {Utils.getBriefText(data.extractFull)}
                                    </Hidden>
                                    <Hidden lgUp>
                                        {Utils.getBriefText(data.extractFull)}
                                    </Hidden>
                                </Fragment>
                            ) : (
                                Utils.getBriefText(data.content)
                            )}
                        </Typography>
                    </Grid>
                    {hoverCard ? (
                        <Fragment>
                            <Hidden mdDown>
                                <Grid
                                    container
                                    direction="row"
                                    justify="flex-end"
                                    alignItems="center"
                                    className={classes.sectSeeMore}
                                >
                                    <a href={Utils.getUrl(data.slug)}>
                                        <Typography className="title">
                                            LEER MÁS
                                        </Typography>
                                    </a>
                                    <a href={Utils.getUrl(data.slug)}>
                                        <Launch className="icon-link" />
                                    </a>
                                </Grid>
                            </Hidden>
                            <Hidden lgUp>
                                <Grid
                                    container
                                    direction="row"
                                    justify="flex-end"
                                    alignItems="center"
                                    className={classes.sectSeeMore}
                                >
                                    <Typography className="title">
                                        <Link href={Utils.getUrl(data.slug)}>
                                            LEER MÁS
                                        </Link>
                                    </Typography>
                                    <Launch className="icon-link" />

                                </Grid>
                            </Hidden>
                        </Fragment>
                    ) : (
                        <Grid
                            container
                            direction="row"
                            justify="center"
                            alignItems="center"
                            className={classes.sectIcon}
                            item lg={2} md={2} sm={2} xs={2}
                        >
                            <a href={Utils.getUrl(data.slug)}>
                                <Launch className="icon-link" />
                            </a>
                        </Grid>
                    )}
                </Grid>
            </Grid>
        </Grid>
    );
}

export default CardNews3;