import React from 'react'
import { primary, secondary } from './utils/paletteApp'
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles'
import newsReducer from './reducers/newsReducer'
import App from './App'
import Latest from './components/latest'
import More from './components/more'
import Filter from './components/filter'
import Banners from './components/banners'
import moment from 'moment'
import 'moment/locale/es'
import Reader from './components/reader'
moment.locale('es')

const theme = createMuiTheme({
  palette: {
    primary,
    secondary
  }
})

export const News = ({ token, url, query }) => (
  <MuiThemeProvider theme={theme}>
    <App token={token} url={url} query={query} />
  </MuiThemeProvider>
)

export const LatestNews = ({ token, limit, url }) => (
  <MuiThemeProvider theme={theme}>
    <Latest token={token} limit={limit} url={url} />
  </MuiThemeProvider>
)

export const MoreNews = ({ token, skip, url }) => (
  <MuiThemeProvider theme={theme}>
    <More token={token} skip={skip} url={url} />
  </MuiThemeProvider>
)

export const FilterNews = () => (
  <MuiThemeProvider theme={theme}>
    <Filter />
  </MuiThemeProvider>
)

export const NewReader = ({ slug, url }) => (
  <MuiThemeProvider theme={theme}>
    <Reader slug={slug} url={url} />
  </MuiThemeProvider>
)

export const BannersNews = ({ token, url, query, userInfo }) => (
  <MuiThemeProvider theme={theme}>
    <Banners token={token} url={url} query={query} userInfo={userInfo} />
  </MuiThemeProvider>
)

export const reducer = newsReducer
