import { FormControl, IconButton, InputAdornment, TextField } from '@material-ui/core';
import { Close, Search } from '@material-ui/icons';
import React, { useState } from 'react'
import { connect } from 'react-redux';
import { filterNews, resetNews } from '../../actions/newsActions';

const Filter = ({ filter, reset }) => {
  const [value, setValue] = useState('');

  const handleChange = (evt) => {
    setValue(evt.target.value);
    filter(evt.target.value);
  };

  const handleReset = () => {
    setValue('');
    reset();
  };

  return (
    <FormControl fullWidth>
      <TextField
        value={value}
        label="Tags"
        placeholder="Buscar..."
        onChange={handleChange}
        variant="outlined"
        fullWidth
        InputProps={{
          startAdornment: (
            <Search />
          ),
          endAdornment: value !== '' ? (
            <InputAdornment position="end">
              <IconButton
                aria-label="reset filter"
                onClick={handleReset}
              >
                <Close />
              </IconButton>
            </InputAdornment>
          ) : null
        }}
      />
    </FormControl>
  )
}

const mapStateToProps = state => {
  return {
    news: state.news
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    filter: (query) => dispatch(filterNews(query)),
    reset: () => dispatch(resetNews())
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Filter);
