import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles(theme => ({
  mainOverview: {
    [theme.breakpoints.up('md')]: {
      padding: '1% 3% 5% 3%'
    },
    [theme.breakpoints.down('md')]: {
      padding: 10
    }
  },
  containerChips: {
    marginTop: '2%',
    [theme.breakpoints.down('md')]: {
      textAlign: 'center'
    }
  },
  containerCardsSection1: {
    paddingLeft: 128,
    paddingRight: 128,
    [theme.breakpoints.down('md')]: {
      paddingLeft: 16,
      paddingRight: 16
    },
    [theme.breakpoints.down('xs')]: {
      paddingLeft: 8,
      paddingRight: 8
    }
  }
}));