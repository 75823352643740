import {
  getNewsAPI,
  getBriefsNewsAPI,
  getNewBySlugAPI
} from "../services/newsService";
import { FILTER_NEWS, RESET_NEWS } from "../actions/types";

export const getNews = (url, token, query) => async (dispatch) => {
  await dispatch(getNewsAPI(url, token, query));
};

export const getBriefsNews = (url, token, query) => async (dispatch) => {
  await dispatch(getBriefsNewsAPI(url, token, query));
};

export const getNewBySlug = (url, slug) => async (dispatch) => {
  await dispatch(getNewBySlugAPI(url, slug));
};

export const filterNews = (filter) => (dispatch) => {
  dispatch({ type: FILTER_NEWS, payload: filter });
};

export const resetNews = () => (dispatch) => {
  dispatch({ type: RESET_NEWS });
};
