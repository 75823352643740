import React, { useState, Fragment } from 'react';
import { Grid, Typography, Hidden, Link } from '@material-ui/core';
import Utils from '../../utils/utils';
import { useStyles } from './styles';
import { Launch } from '@material-ui/icons';


const CardNews2 = ({ data }) => {
    const classes = useStyles();
    const [hoverCard, setHoverCard] = useState(false)

    const handleHoverCard = (option) => {
        setHoverCard(option)
    }

    return (
        <Grid container className={classes.sectCardNews}>
            <img className={classes.imgNews} src={data.image} />
            <Hidden smUp>
                <a href={Utils.getUrl(data.slug)}>
                    <Launch className={classes.iconLinkMobileTop} />
                </a>
            </Hidden>
            <Grid container className={classes.root}
                onMouseEnter={() => handleHoverCard(true)}
                onMouseLeave={() => handleHoverCard(false)}
            >
                <Grid container className={!hoverCard ? classes.gradient : classes.gradientFull} />
                <Grid item lg={!hoverCard ? 10 : 12} md={!hoverCard ? 10 : 12} sm={!hoverCard ? 10 : 12} xs={!hoverCard ? 10 : 12} className={classes.sectText}>
                    <Typography className='title'>
                        {data.title}
                    </Typography>
                    <Typography className='extract'>
                        {hoverCard ? (
                            <Fragment>
                                <Hidden mdDown>
                                    {Utils.getBriefText(data.extractFull)}
                                </Hidden>
                                <Hidden lgUp>
                                    {Utils.getBriefText(data.extractFull).substring(0, 180)}
                                </Hidden>
                            </Fragment>
                        ) : (
                            Utils.getBriefText(data.content)
                        )}
                    </Typography>
                </Grid>
                {hoverCard ? (
                    <Fragment>
                        <Hidden mdDown>
                            <Grid
                                container
                                direction="row"
                                justify="flex-end"
                                alignItems="center"
                                className={classes.sectSeeMore}
                            >
                                <a href={Utils.getUrl(data.slug)}>
                                    <Typography className="title">
                                        LEER MÁS
                                    </Typography>
                                </a>
                                <a href={Utils.getUrl(data.slug)}>
                                    <Launch className="icon-link" style={{ color: '#fff' }} />
                                </a>
                            </Grid>
                        </Hidden>
                        <Hidden lgUp>
                            <Grid
                                container
                                direction="row"
                                justify="flex-end"
                                alignItems="center"
                                className={classes.sectSeeMore}
                            >
                                <a href={Utils.getUrl(data.slug)}>
                                    <Typography className="title">
                                        LEER MÁS
                                    </Typography>
                                </a>
                                <a href={Utils.getUrl(data.slug)}>
                                    <Launch className="icon-link" style={{ color: '#fff' }} />
                                </a>
                            </Grid>
                        </Hidden>
                    </Fragment>
                ) : (
                    <Grid
                        container
                        direction="row"
                        justify="center"
                        alignItems="center"
                        className={classes.sectIcon}
                        item lg={2} md={2} sm={2} xs={2}
                    >
                        <a href={Utils.getUrl(data.slug)}>
                            <Launch className="icon-link" />
                        </a>
                    </Grid>
                )}
            </Grid>
        </Grid>
    );
}

export default CardNews2;