import moment from 'moment';

class PreviewHelpers {
  open = (tabIndex, data) => {
    const sharedData = JSON.parse(sessionStorage.getItem('sharedData'));

    if (data) {
      if (sharedData) {
        sessionStorage.setItem(
          'sharedData',
          JSON.stringify(Object.assign(sharedData, data))
        );
      } else {
        sessionStorage.setItem(
          'sharedData',
          JSON.stringify(Object.assign({}, data))
        );
      }
    }

    if (tabIndex !== undefined || tabIndex !== null) {
      window.listeners.tab.val = tabIndex;
    }
  };

  getShared = (key) => {
    const sharedData = JSON.parse(sessionStorage.getItem('sharedData'));
    return sharedData ? sharedData[key] : {};
  };

  setElementContent = (id, content) => {
    const el = document.getElementById(id);
    el.innerHTML = content;
  };

  setVariable = (name, value) => {
    window.listeners.variable.val = { name, value };
  };

  formatDate = (date, format) => {
    const mmnt = moment(date);
    return mmnt.format(format);
  }
}

export default PreviewHelpers;
