import { observable, runInAction } from 'mobx';
import Utils from '../../../../utils';

class ConfigFormStore {
  @observable title = '';
  @observable category;
  @observable query = null;
  @observable selectedQuery = null;
  @observable variables = [];
  @observable areas = [];
  @observable tabIndex = 0;
  @observable currentPage = 0;
  @observable fetching = false;
  @observable openDeleteConfirmationDialog = false;
  @observable areaToDelete = null;
  @observable qSise = null;

  constructor(qSiseService, loaderStore, snackBarStore) {
    this.qSiseService = qSiseService;
    this.loaderStore = loaderStore;
    this.snackBarStore = snackBarStore;
  }

  initialize = (obj) => {
    runInAction(() => {
      this.title = obj.title;
      this.query = obj.query;
      this.variables = obj.variables;
      this.areas = obj.areas;
      this.category = obj.category ? obj.category.id : undefined;
    });
  };

  setAreas = (area) => {
    if (area !== null) {
      if (area.id) {
        runInAction(() => {
          this.areas
            .filter((a) => {
              return a.id === area.id;
            })
            .map((a) => {
              const newArea = a;
              newArea.title = area.title;
              newArea.description = area.description;
              newArea.menu = area.menu;
              return newArea;
            });
        });
      } else {
        const newArea = Object.assign({ id: Utils.getNewId() }, area);
        this.areas.push(newArea);
      }
    }
  };

  move = (from, to, a) => {
    const tempArr = a;
    return from === to ? tempArr : (tempArr.splice(to, 0, ...tempArr.splice(from, 1)), tempArr);
  }

  moveArea = (index, direction) => {
    if (direction === 'up') {
      runInAction(() => {
        this.areas = this.move(index, index - 1, this.areas);
      });
    }

    runInAction(() => {
      this.areas = this.move(index, index + 1, this.areas);
    });
  }

  setQuery = (query) => {
    runInAction(() => {
      this.query.query = query;
      this.selectedQuery = query;
    });
  };

  addQuery = (query) => {
    runInAction(() => {
      this.query = query;
    });
  };

  setVariable = (variableName, value) => {
    const variables = this.qSise.variables.map((variable) => {
      const v = variable;

      if (v.value === variableName) {
        v.defaultValue = value;
      }

      return v;
    }) || [];
    // for (let i = 0; i < variables.length; i++) {
    //   if (variables[i].value === variableName) {
    //     variables[i].defaultValue = value;
    //   }
    // }
    this.qSise.variables = variables;
  };

  removeQuery = () => {
    runInAction(() => {
      this.selectedQuery = null;
      this.query = null;
    });
  };

  openDeleteModal = (area) => {
    runInAction(() => {
      this.openDeleteConfirmationDialog = true;
      this.areaToDelete = area;
    });
  };

  closeDeleteModal = () => {
    runInAction(() => {
      this.openDeleteConfirmationDialog = false;
      this.areaToDelete = null;
    });
  };

  removeQsiseArea = () => {
    runInAction(() => {
      this.openDeleteConfirmationDialog = false;
      this.areas = this.areas.filter((a) => {
        return a.id !== this.areaToDelete.id;
      });
      this.areaToDelete = null;
    });
  };

  updateQueryProperties = (dataset) => {
    runInAction(() => {
      this.query.criteriaFilter = dataset.criteriaFilter;
      this.query.parameters = dataset.parameters;
    });
  };

  setCurrentPage = (currentPage) => {
    this.currentPage = currentPage;
  };

  getQsiseById = (qSiseId) => {
    return new Promise((resolve, reject) => {
      runInAction(() => {
        this.qSise = null;
        this.loaderStore.start();
        this.fetching = true;
      });
      this.qSiseService
        .get(qSiseId)
        .then((qSise) => {
          runInAction(() => {
            this.qSise = qSise;
            this.fetching = false;
            this.category = qSise.category ? qSise.category.id : '';
            this.loaderStore.end();
            resolve();
          });
        })
        .catch((error) => {
          this.fetching = false;
          this.loaderStore.end();
          this.snackBarStore.setMessage(error);
          reject(error);
        });
    });
  };

  saveQsise = (body) => {
    return new Promise((resolve, reject) => {
      runInAction(() => {
        this.loaderStore.start();
      });
      this.qSiseService
        .save(body)
        .then((response) => {
          this.loaderStore.end();
          resolve(response);
        })
        .catch((error) => {
          this.loaderStore.end();
          this.snackBarStore.setMessage(error);
          reject(error);
        });
    });
  };

  updateQsise = (body) => {
    return new Promise((resolve, reject) => {
      runInAction(() => {
        this.loaderStore.start();
      });
      this.qSiseService
        .update(body)
        .then((response) => {
          this.loaderStore.end();
          resolve(response);
        })
        .catch((error) => {
          this.loaderStore.end();
          this.snackBarStore.setMessage(error);
          reject(error);
        });
    });
  };

  removeQsise = (body) => {
    return new Promise((resolve, reject) => {
      runInAction(() => {
        this.loaderStore.start();
      });
      this.qSiseService
        .delete(body)
        .then((response) => {
          this.loaderStore.end();
          resolve(response);
        })
        .catch((error) => {
          this.loaderStore.end();
          this.snackBarStore.setMessage(error);
          reject(error);
        });
    });
  };
}

export default ConfigFormStore;
