import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => ({
    sectCardNews: {
        position: 'relative',
        backgroundColor: 'transparent',
        height: 359
    },
    imgNews: {
        width: '100%',
        height: '100%',
        borderRadius: 18,
        objectFit: 'cover',
        objectPosition: 'center center'
    },
    gradient: {
        width: '100%',
        height: '100%',
        background: 'linear-gradient(180deg, rgba(255,255,255,0) 5%, rgba(0,0,0,0.6573004201680672) 37%, rgba(0,0,0,1) 72%)',
        position: 'absolute',
        zIndex: -1,
        top: 0,
        left: 0,
        borderBottomRightRadius: 18,
        borderBottomLeftRadius: 18,
    },
    gradientFull: {
        width: '100%',
        height: 515,
        background: 'linear-gradient(180deg, rgba(255,255,255,0) 32%, rgba(0,0,0,0.7049194677871149) 40%, rgba(0,0,0,0.8729866946778712) 50%)',
        position: 'absolute',
        zIndex: -1,
        bottom: 0,
        left: 0,
        borderBottomRightRadius: 18,
        borderBottomLeftRadius: 18,
    },
    root: {
        position: 'absolute',
        bottom: 0,
        borderBottomRightRadius: 18,
        borderBottomLeftRadius: 18,
        backgroundColor: 'transparent',
        zIndex: 1,
        paddingBottom: 30
    },
    sectText: {
        padding: '0px 20px',
        padding: 20,
        '& .title': {
            textShadow: '0px 1px 1px rgba(0, 0, 0, 0.14)',
            fontSize: 14,
            fontWeight: 900,
            letterSpacing: 1.25,
            textTransform: 'uppercase',
            color: 'white'
        },
        '& .extract': {
            fontSize: 13,
            fontWeight: 500,
            letterSpacing: 0.25,
            color: 'white'
        }
    },
    sectSeeMore: {
        paddingRight: 20,
        paddingTop: 20,
        '& a': {
            fontSize: 13,
            color: '#ffffff',
            paddingRight: 5,
        },
        '& .icon-link': {
            color: '#fff',
            [theme.breakpoints.down('xs')]: {
                display: 'none'
            }
        }
    },
    sectIcon: {
        padding: '0px 20px',
        bottom: 0,
        display: 'flex',
        alignItems: 'flex-end',
        '& .icon-link': {
            color: '#fff',
            ['@media (max-width:599px)']: { // eslint-disable-line no-useless-computed-key
                display: 'none'
            }
        }
    },
    iconLinkMobileTop: {
        position: 'absolute',
        right: 25,
        top: 25,
        zIndex: 2,
        color: '#000'
    }
}))