export const GET_NEWS = 'GET_NEWS';
export const GET_NEWS_PENDING = 'GET_NEWS_PENDING';
export const GET_NEWS_FULFILLED = 'GET_NEWS_FULFILLED';
export const GET_NEWS_REJECTED = 'GET_NEWS_REJECTED';

export const GET_LATEST_NEWS = 'GET_LATEST_NEWS';
export const GET_LATEST_NEWS_PENDING = 'GET_LATEST_NEWS_PENDING';
export const GET_LATEST_NEWS_FULFILLED = 'GET_LATEST_NEWS_FULFILLED';
export const GET_LATEST_NEWS_REJECTED = 'GET_LATEST_NEWS_REJECTED';

export const GET_NEWS_BY_SLUG = 'GET_NEWS_BY_SLUG';
export const GET_NEWS_BY_SLUG_PENDING = 'GET_NEWS_BY_SLUG_PENDING';
export const GET_NEWS_BY_SLUG_FULFILLED = 'GET_NEWS_BY_SLUG_FULFILLED';
export const GET_NEWS_BY_SLUG_REJECTED = 'GET_NEWS_BY_SLUG_REJECTED';

export const FILTER_NEWS = 'FILTER_NEWS';
export const RESET_NEWS = 'RESET_NEWS';