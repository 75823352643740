import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => ({
    sectCardNews: {
        position: 'relative',
        textAlign: 'left',
        height: '100%',
        minHeight: 363,
        backgroundColor: '#F4F4FA',
        boxShadow: 'none',
        borderRadius: 18,
        padding: '1.2rem',
        paddingTop: 0
    },
    newsTagContainer: {
        '& > div:not(:last-child)': {
            marginRight: 10
        }
    },
    newsTag: {
        background: 'rgba(228, 218, 249, 0.3)',
        border: '1px solid #D8D0F6',
        boxSizing: 'border-box',
        borderRadius: 16,
        letterSpacing: 0.25,
        color: '#4B4B4B',
        fontSize: 14
    },
    dateContainer: {
        color: '#828282',
        fontSize: 11
    },
    title: {
        fontStyle: 'normal',
        fontWeight: 900,
        fontSize: 14,
        letterSpacing: 0.1,
        textTransform: 'uppercase',
        color: '#4B4B4B'
    },
    extract: {
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: 14,
        letterSpacing: 0.25,
        color: '#4B4B4B',
        paddingTop: 10
    },
    linkContainer: {
        width: '100%',
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'flex-end',
        '& a, & a:active, & a:hover': {
            color: '#000'
        }
    }
}))