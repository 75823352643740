class Listener {
  _val = 0;

  valListener() {}

  set val(v) {
    this._val = v;
    this.valListener(v);
  }

  get val() {
    return this._val;
  }

  onChange(listener) {
    this.valListener = listener;
  }
}

class PreviewListeners {
  initialize = () => {
    window.listeners = {
      tab: new Listener(),
      variable: new Listener()
    };
  };

  addEventListener = (key, func) => {
    window.listeners[key].onChange(func);
  };

  setListenerValue = (key, value) => {
    window.listeners[key].val = value;
  };

  removeEventListener = (key) => {
    window.listeners[key].onChange(null);
  };

  close = () => {
    delete window.listeners;
  };
}

export default PreviewListeners;
