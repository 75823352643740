import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => ({
    sectCardNews: {
        position: 'relative',
        backgroundColor: 'white',
        height: 359,
        borderRadius: 18
    },
    newsTagContainer: {
        '& > div:not(:last-child)': {
            marginRight: 10
        }
    },
    newsTag: {
        background: 'rgba(228, 218, 249, 0.3)',
        border: '1px solid #D8D0F6',
        boxSizing: 'border-box',
        borderRadius: 16,
        letterSpacing: 0.25,
        color: '#4B4B4B',
        fontSize: 14
    },
    dateContainer: {
        color: '#828282',
        fontSize: 11
    },
    sectImage: {
        height: '61%',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover'
    },
    sectImageFull: {
        height: '20%',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        borderTopLeftRadius: 18,
        borderTopRightRadius: 18
    },
    heightSectImageFull: {
        height: '80%'
    },
    imgNews: {
        width: '100%',
        height: '100%',
        borderRadius: 18
    },
    root: {
        bottom: 0,
        borderBottomRightRadius: 18,
        borderBottomLeftRadius: 18,
        backgroundColor: 'white',
        zIndex: 1,
        height: '100%',
        padding: '0px .5rem',
        paddingBottom: 20
    },
    sectText: {
        '& .title': {
            fontSize: 14,
            fontWeight: 900,
            letterSpacing: 1.25,
            textTransform: 'uppercase',
            color: '#000000'
        },
        '& .extract': {
            fontSize: 13,
            fontWeight: 500,
            letterSpacing: 0.25,
            color: '#000000'
        }
    },
    sectSeeMore: {
        position: 'absolute',
        bottom: 25,
        paddingRight: 20,
        paddingTop: 20,
        '& a': {
            fontSize: 13,
            color: '#000000',
            paddingRight: 5,
        },
        '& .icon-link': {
            color: '#000000',
            [theme.breakpoints.down('xs')]: {
                display: 'none'
            }
        }
    },
    sectIcon: {
        padding: '0px 20px',
        bottom: 0,
        display: 'flex',
        alignItems: 'flex-end',
        '& .icon-link': {
            position: 'relative',
            // [theme.breakpoints.down('md')]: {
            //     position: 'absolute',
            //     top: 26,
            //     right: 33
            // }
        }
    },
    iconLinkMobileTop: {
        position: 'absolute',
        right: 25,
        top: 25,
        zIndex: 2
    }
}))