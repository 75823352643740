export const getToken = () => {
  return localStorage.getItem('query-execute:token');
};

const checkStatus = (response) => {
  return new Promise((resolve, reject) => {
    if (response.status === 401) {
      console.error(response);
    }
    if (response.ok) {
      resolve(response);
    } else {
      response.json().then((responseError) => {
        reject(responseError.message);
      }).catch(() => {
        reject(response.statusText);
      });
    }
  });
};

export const checkStatusWithFullErrorResponse = (response) => {
  return new Promise((resolve, reject) => {
    if (response.status === 401) {
      console.error(response);
    }
    if (response.status === 200) {
      resolve(response);
    } else {
      response.json().then((responseError) => {
        reject(responseError);
      }).catch(() => {
        reject(response.statusText);
      });
    }
  });
};

export default checkStatus;
