import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => ({
    sectCardNews: {
        position: 'relative',
        backgroundColor: 'white',
        height: 359,
        borderRadius: 18
    },
    newsTagContainer: {
        '& > div:not(:last-child)': {
            marginRight: 10
        }
    },
    newsTag: {
        background: 'rgba(228, 218, 249, 0.3)',
        border: '1px solid #D8D0F6',
        boxSizing: 'border-box',
        borderRadius: 16,
        letterSpacing: 0.25,
        color: '#4B4B4B',
        fontSize: 14
    },
    dateContainer: {
        color: '#828282',
        fontSize: 11
    },
    sectImage: {
        borderRadius: 18,
        height: '100%',
        '& .image': {
            height: '100%',
            borderRadius: 18,
            width: '100%'
        }
    },
    root: {
        padding: 20,
        position: 'relative',
        height: '100%'
    },
    sectText: {
        '& .title': {
            paddingTop: 20,
            fontSize: 14,
            fontWeight: 900
        },
        '& .extract': {
            paddingTop: 20,
            fontSize: 13
        }
    },
    sectActions: {
        position: 'relative',
        bottom: 0,
        display: 'flex',
        justifyContent: 'space-between',
        width: '100%',
        alignItems: 'flex-end'
    }
}))