import moment from "moment";

const Utils = {
  arrayMove: (array, from, to) => {
    const tempArray = array.slice();
    const startIndex = to < 0 ? tempArray.length + to : to;
    const item = tempArray.splice(from, 1)[0];
    tempArray.splice(startIndex, 0, item);
    return tempArray;
  },
  getObjectByType: (type, options) => {
    switch (type) {
      case "string":
        return { values: options.join(",") };
      case "numeric":
        return {
          min: options[0],
          max: options[1],
          preffix: options[2],
          suffix: options[3],
        };
      case "bool":
        return {
          affirmativeLabel: options[0],
          negativeLabel: options[1],
        };
      default:
        console.log("Invalid type!");
        break;
    }
  },
  getUploadedFileDimensions: (file) =>
    new Promise((resolve, reject) => {
      try {
        const img = new Image();

        img.onload = () => {
          const width = img.naturalWidth;
          const height = img.naturalHeight;

          window.URL.revokeObjectURL(img.src);

          return resolve({ width, height });
        };

        img.src = window.URL.createObjectURL(file);
      } catch (exception) {
        return reject(exception);
      }
    }),
  dateToFormatUTC: (value, format) =>
    value ? moment(value).utc().format(format) : "",
  dateToFormat: (value, format) => (value ? moment(value).format(format) : ""),
  dateBetween2Dates: (date1, date2, type = "minutes") =>
    moment(date2).diff(moment(date1), type),
  showDiffTimes: (date1, date2) => {
    let diff = Utils.dateBetween2Dates(date1, date2, "minutes");
    let type = "minutos";
    let newDiff = 0;

    if (diff > 59) {
      newDiff = Utils.dateBetween2Dates(date1, date2, "hours");
      type = "horas";
      if (newDiff > 23) {
        type = "dias";
        newDiff = Utils.dateBetween2Dates(date1, date2, "days");
      }
      diff = newDiff;
    }

    return `${diff} ${type}`;
  },
  isEmptyObject: (obj) => {
    return Object.keys(obj).length === 0;
  },
  slugify: (string) => {
    const a =
      "àáâäæãåāăąçćčđďèéêëēėęěğǵḧîïíīįìłḿñńǹňôöòóœøōõőṕŕřßśšşșťțûüùúūǘůűųẃẍÿýžźż·/_,:;";
    const b =
      "aaaaaaaaaacccddeeeeeeeegghiiiiiilmnnnnoooooooooprrsssssttuuuuuuuuuwxyyzzz------";
    const p = new RegExp(a.split("").join("|"), "g");

    let newSlug = string
      .toString()
      .toLowerCase()
      .replace(/\s+/g, "-") // Replace spaces with -
      .replace(p, (c) => b.charAt(a.indexOf(c))) // Replace special characters
      .replace(/&/g, "-and-") // Replace & with 'and'
      .replace(/[^\w\-]+/g, "") // Remove all non-word characters
      .replace(/\-\-+/g, "-") // Replace multiple - with single -
      .replace(/^-+/, "") // Trim - from start of text
      .replace(/-+$/, ""); // Trim - from end of text

    // This is to avoid a possible repeat slug at some point
    const randomId = Math.floor(Math.random() * 10000) + 100;
    newSlug = `${newSlug}-${randomId}`;

    return newSlug;
  },
  getBriefText: (data) => {
    if (!data) {
      return "";
    }

    let textWithoutHTML = data.replace(/<[^>]*>?/gm, "3e3");
    textWithoutHTML = textWithoutHTML.replace(/3e3/gm, ""); //fix space between words
    textWithoutHTML = textWithoutHTML.split("&nbsp;").join(" ");
    //textWithoutHTML = textWithoutHTML.substring(0, 300) //brief text

    return `${textWithoutHTML}...`;
  },
  formatCoin: (num, decimals = true) => {
    if (num === null) return;

    return num
      .toFixed(decimals ? 2 : 0)
      .replace(".", ",")
      .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.");
  },
  openFile: (url = false) => {
    if (url) {
      window.open(url, "_blank");
    }
  },
  getUrl: (slug) => {
    const url = window.location.origin;
    return `${url}/news/read/${slug}`;
  },
};

export default Utils;
