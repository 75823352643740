(function () {
  const template = document.createElement('template');
  template.innerHTML = `
    <style>
      :host {
        display: flex;
        flex-direction: column;
        width: 100%;
        contain: content;
        background-color: #fff;
        border-radius: 15px;
      }

      :host #sise-slider {
        width: 100%;
      }

      #sise-slides {
        box-shadow: 0 2px 2px rgba(0, 0, 0, .3) !important;
        background-color: #fff !important;
        border-radius: 3px !important;
        padding: 16px !important;
        height: auto !important;
        overflow: auto !important;
        transition: 0.5s;
        border-bottom: 1px solid #F4F4FA;
      }

      #sise-slides ::slotted(*) {
        outline: 0 !important;
      }

      #sise-nav {
        display: flex;
        justify-content: space-between;
        align-items: center;
        background-color: #fff;
        padding: .5rem;
      }

      #sise-nav ::slotted(*) {
        flex: 0 0 auto;
        color: #4C1DB7;
        padding: 12px;
        overflow: visible;
        font-size: 1.5rem;
        text-align: center;
        transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
        border-radius: 50%;
        border: 0;
        cursor: pointer;
        margin: 0;
        display: inline-flex;
        outline: 0;
        position: relative;
        align-items: center;
        user-select: none;
        vertical-align: middle;
        -moz-appearance: none;
        justify-content: center;
        text-decoration: none;
        background-color: transparent;
        -webkit-appearance: none;
        -webkit-tap-highlight-color: transparent;
      }

      #sise-slider {
        display: none;
        -webkit-user-select: none !important;
        user-select: none !important;

      }
      #sise-slider slot {
        display: inline-flex !important; /* Safari bug. Treats <slot> as a parent */
        width: 100% !important;
      }
      /* Safari does not support #id prefixes on ::slotted
        See https://bugs.webkit.org/show_bug.cgi?id=160538 */
      #sise-slider ::slotted(*) {
        font-size: 14px !important;
        font-family: Roboto, sans-serif !important;
        padding: 8px !important;
        margin: 0 !important;
        text-align: center !important;
        text-overflow: ellipsis !important;
        white-space: nowrap !important;
        overflow: hidden !important;
        cursor: pointer !important;
        background-color: #DDDDDD !important;
        border: none !important; /* if the user users a <button> */
        flex: 1 !important;
        outline: 0 !important;
        max-height: 33px !important;
      }
      #sise-slider ::slotted([aria-selected="true"]) {
        font-weight: 600 !important;
        background-color: #D1D1D1 !important;
        box-shadow: none !important;
      }
      #sise-slider ::slotted(:focus) {
        z-index: 1 !important; /* make sure focus ring doesn't get buried */
      }
      #sise-slides ::slotted([aria-hidden="true"]) {
        display: none !important;
        transition: 0.5s;
      }
    </style>
    <div id="sise-slider">
      <slot id="sliderSlot" name="title"></slot>
    </div>
    <div id="sise-slides">
      <slot id="slidesSlot"></slot>
    </div>
    <div id="sise-nav">
      <slot id="navsSlot" name="nav"></slot>
    </div>
  `;

  let selected_ = null;

  class Slider extends HTMLElement {
    constructor() {
      super();

      this.attachShadow({ mode: 'open' });
      this.shadowRoot.appendChild(template.content.cloneNode(true));
      const prevBtn = document.createElement('button');
      prevBtn.setAttribute('id', 'prevBtn');
      prevBtn.setAttribute('slot', 'nav');
      prevBtn.innerHTML = '<';
      this.appendChild(prevBtn);
      const nextBtn = document.createElement('button');
      nextBtn.setAttribute('id', 'nextBtn');
      nextBtn.setAttribute('slot', 'nav');
      nextBtn.innerHTML = '>';
      this.appendChild(nextBtn);
      this.childNodes.forEach((child) => {
        if (child.nodeType === 1 && child.nodeName !== 'BUTTON') {
          const button = document.createElement('button');
          button.setAttribute('slot', 'title');
          button.innerHTML = child.title;
          this.appendChild(button);
          const section = document.createElement('section');
          section.innerHTML = child.innerHTML;
          this.appendChild(section);
        }
      });
    }

    get selected() {
      return selected_;
    }

    set selected(idx) {
      selected_ = idx;

      this.selectSlide(idx);

      this.setAttribute('selected', idx);
    }

    connectedCallback() {
      this.setAttribute('role', 'tablist');
      const sliderSlot = this.shadowRoot.querySelector('#sliderSlot');
      const slidesSlot = this.shadowRoot.querySelector('#slidesSlot');
      const navsSlot = this.shadowRoot.querySelector('#navsSlot');
      const navs = navsSlot.assignedNodes({ flatten: true });
      this.slider = sliderSlot.assignedNodes({ flatten: true });
      this.slides = slidesSlot
        .assignedNodes({ flatten: true })
        .filter((el) => {
          return el.nodeType === Node.ELEMENT_NODE;
        })
        .filter((el) => {
          return el.nodeName === 'SECTION';
        });

      // Add aria role="tabpanel" to each content panel.
      for (const slide of this.slides.entries()) {
        slide[1].setAttribute('role', 'tabpanel');
        slide[1].setAttribute('tabindex', 0);
      }

      // Save refer to we can remove listeners later.
      this._boundOnTitleClick = this._onTitleClick.bind(this);
      this._boundPrevClick = this.prev.bind(this);
      this._boundNextClick = this.next.bind(this);
      navs[0].addEventListener('click', this._boundPrevClick);
      navs[1].addEventListener('click', this._boundNextClick);
      sliderSlot.addEventListener('click', this._boundOnTitleClick);

      this.selected = this._findFirstSelectedTab() || 0;
    }

    disconnectedCallback() {
      const sliderSlot = this.shadowRoot.querySelector('#sliderSlot');
      sliderSlot.removeEventListener('click', this._boundOnTitleClick);
    }

    _onTitleClick(e) {
      if (e.target.slot === 'title') {
        this.selected = this.slider.indexOf(e.target);
        e.target.focus();
      }
    }

    next() {
      if (this.selected < this.slides.length - 1) {
        this.selected = this.selected + 1;
      }
    }

    prev() {
      if (this.selected > 0) {
        this.selected = this.selected - 1;
      }
    }

    _findFirstSelectedTab() {
      let selectedIdx;
      for (const [i, slide] of this.slider.entries()) {
        slide.setAttribute('role', 'tab');
        // Allow users to declaratively select a tab
        // Highlight last tab which has the selected attribute.
        if (slide.hasAttribute('selected')) {
          selectedIdx = i;
        }
      }
      return selectedIdx;
    }

    selectSlide(idx = null) {
      for (let i = 0, slide; (slide = this.slider[i]); ++i) {
        const select = i === idx;
        slide.setAttribute('tabindex', select ? 0 : -1);
        slide.setAttribute('aria-selected', select);
        this.slides[i].setAttribute('aria-hidden', !select);
      }
    }
  }
  customElements.define('sise-slider', Slider);

  class Slide extends HTMLElement {
    connectedCallback() {
      this.remove();
    }
  }
  customElements.define('sise-slide', Slide);
}());
