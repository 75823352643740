import React, { useEffect, Fragment } from 'react'
import { connect } from 'react-redux'
import { getNews } from '../../actions/newsActions'
import Utils from '../../utils/utils'
import {
  Grid,
  Typography,
  Container,
  Button,
  Avatar,
  Link
} from '@material-ui/core'
import { Launch } from '@material-ui/icons'
import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/swiper-bundle.min.css'
import 'swiper/swiper.min.css'
import 'swiper/components/pagination/pagination.min.css'
import 'swiper/components/navigation/navigation.min.css'
import { useStyles } from './styles'
import SwiperCore, { Pagination } from 'swiper'

const Banners = ({ token, news, getMoreNews, url, query, userInfo }) => {
  const classes = useStyles()
  SwiperCore.use([Pagination])

  const executeInitnews = async () => {
    await getMoreNews(url, token, query)
  }

  useEffect(() => {
    if (token) {
      if (!news.data.length > 0) {
        executeInitnews()
      }
    }
  }, [token])

  const excludeNotProfile = () => {
    const auxNew = news
    return auxNew.filteredData.filter((e) => e?.newStyle === 'noticia5')
  }

  const excludeProfile = () => {
    const auxNew = news
    return auxNew.filteredData.filter((e) => e?.newStyle === 'noticia6')
  }

  return (
    <Swiper
      className={classes.banner}
      slidesPerView={1}
      pagination={{ clickable: true, type: 'bullets' }}
      modules={[Pagination]}
    >
      {excludeNotProfile().map((item, index) => (
        <SwiperSlide key={index}>
          <Grid
            className={classes.infoBannerDefault}
            container
            spacing={0}
            align='center'
            direction='column'
            style={{
              backgroundImage: `url(${item?.image})`
            }}
          >
            <Grid item>
              <Fragment>
                <Avatar
                  style={{
                    width: 92,
                    height: 92
                  }}
                  src={userInfo?.data?.pictureUrl || ''}
                >
                  {userInfo?.data?.firstName ? (
                    <Typography
                      style={{
                        fontStyle: 'uppercase'
                      }}
                    >
                      {`${userInfo.data.firstName.charAt(
                        0
                      )}${userInfo.data.lastName.charAt(0)}`}
                    </Typography>
                  ) : null}
                </Avatar>
                <Grid>
                  <Typography
                    style={{
                      marginTop: 21,
                      color: '#fff',
                      fontSize: 34,
                      lineHeight: '36px',
                      fontWeight: 500,
                      letterSpacing: '-1px'
                    }}
                  >
                    {userInfo?.data?.firstName
                      ? `¡Hola, ${userInfo.data.firstName}!`
                      : '¡Hola Asegurado!'}
                  </Typography>
                </Grid>
              </Fragment>
            </Grid>
          </Grid>
        </SwiperSlide>
      ))}

      {excludeProfile().map((item, index) => (
        <SwiperSlide key={index}>
          <Grid
            className={classes.infoBanner}
            container
            spacing={0}
            alignItems='flex-end'
            style={{
              backgroundImage: `url(${item?.image})`
            }}
          >
            <Grid item>
              <Fragment>
                <Grid>
                  <Typography
                    style={{
                      marginTop: 21,
                      color: '#fff',
                      fontSize: 34,
                      lineHeight: '36px',
                      fontWeight: 500,
                      letterSpacing: '-1px',
                      fontFamily: 'Work Sans',
                      textShadow: '1px 1px 5px #000000fa'
                    }}
                  >
                    {item?.title || ''}
                  </Typography>
                  {item?.content.length > 0 && (
                    <a
                      href={Utils.getUrl(item.slug)}
                      className={classes.sectSeeMore}
                    >
                      <Typography>LEER MÁS</Typography>
                      <Launch />
                    </a>
                  )}
                </Grid>
              </Fragment>
            </Grid>
          </Grid>
        </SwiperSlide>
      ))}
    </Swiper>
  )
}

const mapStateToProps = (state) => {
  return {
    news: state.news
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getMoreNews: (url, token, query) => dispatch(getNews(url, token, query))
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(Banners)
