import React from 'react'
import { CircularProgress, Grid } from '@material-ui/core';

const Loader = ({ theme }) => {
  console.log('Theme', theme);
  return (
    <Grid container justify="center" alignItems="center" style={{ padding: '1rem' }}>
      <CircularProgress style={{ color: theme && theme.primary.main }} />
    </Grid>
  );
}

export default Loader;
