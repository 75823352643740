export const quotationUrlBase = 'https://test-quotation.iconnectance.com/';
//export const quotationUrlBase = "http://localhost:8088/";
export const quotationUrlApi = `${quotationUrlBase}v1/`;

export const validateResponse = (response) => {
  if (response.ok) {
    return response.json();
  } else {
    throw exeptionCodeResponse();
  }
};

export const validateTextResponse = (response) => {
  if (response.ok) {
    return response.text();
  } else {
    throw exeptionCodeResponse();
  }
};

export const exeptionCodeResponse = (error = "error") => {
  return error;
};

export const getToken = () => {
  const local = localStorage.getItem("persist:root_cotizador_backoffice");
  const localParse = JSON.parse(local);
  return localParse.auth.token;
};